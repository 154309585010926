import React from "react";
import "./Footer.css";
import { assets } from "../../assets/assets";

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="footer-content">
        <div className="footer-content-left">
          <img src={assets.expo} alt="FMS Education Logo" className="footer-logo" />
          <img src={assets.unilogo} alt="FMS Education Logo" className="footer-logo2" />
          <img src={assets.MITLogo} alt="FMS Education Logo" className="footer-logo2" />
          <p>
            Your premier destination for quality education, expert guidance, and innovative solutions. Serving with dedication and excellence.
          </p>
          <div className="footer-social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={assets.facebook_icon} alt="Facebook" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={assets.twitter_icon} alt="Twitter" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={assets.linkedin_icon} alt="LinkedIn" />
            </a>
           
          </div>
        </div>
        <div className="footer-content-center">
          <h2>Links</h2>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#my-5">About Us</a></li>
            <li><a href="#container">Guidelines</a></li>
            <li><a href="#containera">Awards</a></li>
            <li><a href="#gallery">Gallery</a></li>
            <li><a href="#footer">Contact</a></li>
          </ul>
        </div>
        <div className="footer-content-right">
          <h2>Get in Touch</h2>
          <ul>
            <li>Department of Industrial Management,</li>
            <li>Faculty of Science,</li>
            <li>University of Kelaniya,</li>
            <li>Dalugama, Kelaniya,</li>
            <li>Sri Lanka</li>
            <div className="contact">
              <img src={assets.phone} alt="Phone" />034-3305689
            </div>
            <div className="contact">
              <img src={assets.email} alt="Email" />email@gmail.com
            </div>
          </ul>
        </div>
      </div>
      <hr />
      <p className="footer-copyright">© 2024 Exposition™. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
