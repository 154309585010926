import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { assets } from '../../assets/assets'; // Adjust the path as necessary
import backImage from '../../assets/backimage1.jpg'; // Adjust the path as necessary
import './Header.css'

const Header = () => {
  const [headingSize, setHeadingSize] = useState('2.5rem');
  const [paragraphSize, setParagraphSize] = useState('1.5rem');
  const [isVisible, setIsVisible] = useState({
    logo: false,
    heading: false,
    paragraph: false,
  });
  const [headerHeight, setHeaderHeight] = useState('100vh'); // Default to full height

  useEffect(() => {
    const handleResize = () => {
      if ( window.innerWidth < 576 && window.innerWidth > 321 ) {
        setHeadingSize('1rem');
        setParagraphSize('0.8rem');
        setHeaderHeight('60vh'); // Use auto height for smaller screens
      }
      else if (window.innerWidth < 321) {
        setHeadingSize('0.8rem');
        setParagraphSize('0.735rem');
        setHeaderHeight('60vh'); // Use auto height for smaller screens
      } else {
        setHeadingSize('2.5rem');
        setParagraphSize('1.5rem');
        setHeaderHeight('100vh'); // Full height for larger screens
      }     
      
    };

    const fadeInDelay = 300; // Delay for fade-in effect

    setTimeout(() => {
      setIsVisible({
        logo: true,
        heading: true,
        paragraph: true,
      });
    }, fadeInDelay);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div 
      className="text-center"  id="home"
      style={{ 
        height: headerHeight, // Conditional height based on screen size
        background: `url(${backImage}) no-repeat center center`, 
        backgroundSize: 'cover', // Ensure the background covers the entire area
        color: '#ffffff',
        fontFamily: 'Roboto, sans-serif',
        display: 'flex', // Use flex to center content
        flexDirection: 'column',
        justifyContent: 'center', // Center vertically
        alignItems: 'center', // Center horizontally
      }}
    >
      <Container fluid style={{ maxWidth: '100%' }}> {/* Prevent exceeding the viewport width */}
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <Image
              src={assets.edify}
              alt="Edify Logo"
              className={`mb-4 img-fluid ${isVisible.logo ? 'fade-in' : ''}`}
              style={{ 
                maxWidth: '22%', 
                height: 'auto', 
                opacity: isVisible.logo ? 1 : 0, 
                transition: 'opacity 1.5s ease-in-out' 
              }}
            />
            <h1 
              className={`display-5 ${isVisible.heading ? 'fade-in' : ''}`} 
              style={{ 
                fontWeight: '700',
                color: '#c69960',
                fontSize: headingSize,
                opacity: isVisible.heading ? 1 : 0,
                transition: 'opacity 1.5s ease-in-out', 
                fontFamily: 'Poppins, sans-serif'
              }}
            >
              INTER UNIVERSITY ARTICLE COMPETITION
            </h1>
            <p 
              className={`lead ${isVisible.paragraph ? 'fade-in' : ''}`} 
              style={{ 
                 fontWeight: '500',
                maxWidth: '1500px', 
                margin: '0 auto', 
                lineHeight: '1.8', 
                fontSize: paragraphSize,
                opacity: isVisible.paragraph ? 1 : 0,
                transition: 'opacity 1.5s ease-in-out',
               fontFamily: 'Poppins, sans-serif'
                
              }}
            >
              Welcome to Edify 2024<br />
              The inter-university article competition held in conjunction with Exposition Issue 20. Edify aims to foster a connection between academia and industry by showcasing the collective knowledge of undergraduates.
            </p>
            
            <p 
              className={`lead ${isVisible.paragraph ? 'fade-in' : ''}`} 
              style={{ 
                fontWeight: '500',
                maxWidth: '800px', 
                margin: '0 auto', 
                lineHeight: '1.8', 
                fontSize: paragraphSize,
                opacity: isVisible.paragraph ? 1 : 0,
                transition: 'opacity 1.5s ease-in-out', 
                fontFamily: 'Poppins, sans-serif'
                
              }}
            >
              Are you ready to let your creativity captivate the entire industry?<br />
              Edify is where it all begins!
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
