import React from 'react';
import { assets } from '../../assets/assets';
import './Awards.css'; // Import the CSS file for custom styles

const Awards = () => {
  return (
    <div className="containera" id='containera'>
    <div className="container mt-5">
      <h6 className="text-center mb-4">Awards</h6>
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <img src={assets.c1} className="card-img-top" alt="Winner Trophy" />
            <div className="card-body text-center">
              <h5 className="card-title">Winner</h5>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <img src={assets.c2} className="card-img-top" alt="2nd Runner-Up Trophy" />
            <div className="card-body text-center">
              <h5 className="card-title">1st Runner-Up</h5>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <img src={assets.c3} className="card-img-top" alt="1st Runner-Up Trophy" />
            <div className="card-body text-center">
              <h5 className="card-title">2nd Runner-Up</h5>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <img src={assets.c4} className="card-img-top" alt="Most Popular Trophy" />
            <div className="card-body text-center">
              <h5 className="card-title">Most Popular</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Awards;
